<template>
    <el-dialog
        :visible.sync="visible"
        :before-close="closeModal"
        width="500px"
        top="40px"
        :close-on-click-modal="false"
        @closed="resetForm"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.change_password') }}
                </p>
            </div>
        </span>

        <div class="mb-20 mt-8 px-10">
            <el-form
                ref="form"
                v-loading="$wait.is('updating_password')"
                :model="formData"
                label-position="top"
                :rules="rules"
                @submit.native.prevent
                @keyup.native.enter="updatePassword"
            >
                <el-form-item :label="$t('clients.password')" prop="password">
                    <el-input v-model="formData.password" type="password" autocomplete="new-password" />
                </el-form-item>
                <el-form-item :label="$t('clients.repeat_password')" prop="password_repeat">
                    <el-input v-model="formData.password_repeat" type="password" autocomplete="new-password" />
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('updating_password')" @click="updatePassword">{{ $t('common.update') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from './information.api';

export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            formData: {
                password:        '',
                password_repeat: '',
            },
            rules: {
                password: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                ],
                password_repeat: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                ],
            },
        };
    },

    methods: {
        async updatePassword() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('updating_password');
            try {
                await Api.updatePassword(this.$route.params.uuid, this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.closeModal();
            } finally {
                this.$wait.end('updating_password');
            }
        },

        closeModal() {
            this.$emit('close');
        },

        resetForm() {
            this.$refs.form.resetFields();
            this.formData = {};
        },
    },
};
</script>
